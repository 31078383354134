.post-page {
    padding: 80px 80px ;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .post-page h3 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align:left;
    color: #0c4670;
  }
  
  .post-page img {
    width: 690px;
    height:345px ;
    
    margin-bottom: 20px;
   
  }
  
  .post-page .date {
    font-size: 0.9em;
    color: #777;
    margin-bottom: 10px;
  }
  
  .post-page .category {
    font-size: 1em;
    color: #0c4670;
    margin-bottom: 20px;
  }
  
  .post-page p {
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .post-page .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .post-page .navigation-button {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #0c4670;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .post-page .navigation-button:hover {
    background-color: #0c4670;
  }
  
  .post-page .navigation-button img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .post-page .navigation-button span {
    font-size: 1em;
  }
  
  .post-page a {
    display: inline-block;
    margin-top: 20px;
    color: #0c4670;
    text-decoration: none;
    font-weight: bold;
  }
  
  .post-page a:hover {
    text-decoration: underline;
  }
  .post-page .back-link {
    display: inline-block;
    margin-top: 20px;
    color: #0c4670;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .post-page .back-link:hover {
    color: #0c4670;
  }

  