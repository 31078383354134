@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.listing-details {
    width: 100vw;
    padding: 120px 150px 40px 150px;
    font-family: 'Poppins', sans-serif;
}

.listing-details .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.listing-details .title h1 {
    font-size: 40px;
    color: #0C4670;
    margin: 20px 0;
}

.listing-details .photos {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px 0;
}

.listing-details .photos img {
    width: 280px;
    object-fit: cover;
}

.listing-details .hotel-info-box,
.listing-details .highlights,
.room-details {
    border: 1px solid #ccc;
    padding: 20px;
    margin: 20px 0;
    background-color: #f9f9f9;
    border-radius: 10px;
}

.listing-details h2 {
    font-size: 30px;
}

.listing-details .address {
    padding-left: 0;
    font-size: 14px;
}

.listing-details p {
    font-size: 15px;
}



.room-details-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.room-details-table th, .room-details-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.room-details-table td.thd{
text-align: left;
}

.room-details-table th {
    background-color: #f2f2f2;
 
}

.room-details-table img {
    width: 150px;
    height: 150px;
    margin-right: 5px;
}

.room-details-table ul {
    padding-left: 10px;
    list-style-type: none;
}

a.map-link {
    text-decoration: none;
    color: inherit;
}

a.map-link:hover {
    text-decoration: underline;
}

.see-map {
    color: #0C4670;
}

hr {
    margin: 20px 0;
}

.listing-details .offers {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.highlight-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
}

.highlight {
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 30%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.highlight-icon {
    margin-right: 10px;
    font-size: 24px;
}

.listing-details .offers .amenities {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.listing-details .offers .amenities .facility {
    width: 200px;
    height: 120px;
}

.listing-details .comments-section h2 {
    font-size: 20px;
}

.listing-details .comments-section .comment-box {
    font-size: 12px;
    padding: 20px 80px;
    height: auto;
    width: 100%;
    margin-bottom: 20px;
}

.listing-details .comments-section .comment-box .comment {
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(187, 186, 186);
}

.comments-section .comment-box .comment .commant-profile {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
}

.comments-section .comment-box .comment .commant-profile h4 {
    font-size: 14px;
    display: flex;
    align-items: last baseline;
}

.listing-details .comments-section .comment-box .comment h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.listing-details .comments-section .comment-box .comment h5 {
    font-size: 10px;
    color: grey;
}

.comments-section .comment-box .comment .commant-profile img {
    width: 40px;
    height: 40px;
}

.listing-details .review-box {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.listing-details .review-box h2 {
    font-size: 20px;
}

.listing-details .review-box textarea {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
}

.listing-details .review-box .comment_btn {
    background-color: #0C4670;
    color: #fff;
    width: 200px;
    border: none;
    font-size: 15px;
    padding: 15px 5px;
    border-radius: 5px;
}

.listing-details .review-box .comment_btn:hover {
    cursor: pointer;
}

.listing-details .creator-actions {
    display: flex;
    gap: 20px;
    justify-content: end;
}

.listing-details .creator-actions .edit_btn {
    padding: 10px 30px;
    font-size: 20px;
    background-color: #0C4670;
    color: #fff;
    border: none;
    border-radius: 5px;
}

.listing-details .creator-actions .edit_btn:hover {
    cursor: pointer;
    background-color: #082f4a;
}

.listing-details .creator-actions .delete_btn {
    padding: 10px 30px;
    font-size: 20px;
    background-color: red;
    color: #fff;
    border: none;
    border-radius: 5px;
}

.listing-details .creator-actions .delete_btn:hover {
    cursor: pointer;
    background-color: rgb(120, 0, 0);
}
.box ul{
    list-style-type: none;
}

.views{
    background: linear-gradient(to right, #4174c7, #20346f);
    width: 260px;
    display: flex;
    margin-top: 5px;
   
    
   
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: left;
}

.views p{
    padding-left: 0px;
    color: white;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .listing-details {
        padding: 100px 50px 30px 50px;
    }
}

@media (max-width: 992px) {
    .listing-details {
        padding: 80px 30px 20px 30px;
    }

    .listing-details .title h1 {
        font-size: 35px;
    }

    .listing-details .photos img {
        width: 200px;
    }

    .highlight {
        width: 45%;
    }
}

@media (max-width: 768px) {
    .listing-details {
        padding: 60px 20px 20px 20px;
    }

    .listing-details .title {
        flex-direction: column;
        align-items: flex-start;
    }

    .listing-details .title h1 {
        font-size: 30px;
    }

    .listing-details .photos img {
        width: 100%;
    }

    .highlight {
        width: 100%;
    }

    .comments-section .comment-box {
        padding: 10px 20px;
    }

    .listing-details .review-box .comment_btn {
        width: 100%;
    }

    .listing-details .creator-actions {
        flex-direction: column;
        align-items: flex-start;
    }

    .listing-details .creator-actions .edit_btn,
    .listing-details .creator-actions .delete_btn {
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 576px) {
    .listing-details {
        padding: 40px 10px 10px 10px;
    }

    .listing-details .title h1 {
        font-size: 25px;
    }

    .listing-details h2 {
        font-size: 24px;
    }

    .listing-details p,
    .listing-details .address,
    .comments-section .comment-box .comment h3 {
        font-size: 14px;
    }

    .room-details-table-wrapper {
        display: none;
    }

    .room-details-boxes {
        display: block;
    }

    .room-detail-box {
        border: 1px solid #ddd;
        padding: 10px;
        margin-bottom: 10px;
        background-color: #f9f9f9;
        border-radius: 5px;
    }
}



@media only screen and (max-width: 768px) {
    .room-details-table, .room-details-table tbody, .room-details-table tr, .room-details-table td, .room-details-table th {
        display: block;
        width: 100%;
    }

    .room-details-table tr {
        margin-bottom: 15px;
    }

    .room-details-table td {
        text-align: left;
        padding: 10px;
        position: relative;
        border: none;
        border-bottom: 1px solid #ddd;
    }

    .room-details-table td::before {
        content: attr(data-label) ": ";
        font-weight: bold;
        display: inline-block;
        margin-right: 5px;
    }

    .room-details-table th {
        display: none;
    }

    .room-details-table td img {
        width: 100px;
        height: 100px;
    }

    .room-details-table td .room-type {
        display: flex;
        align-items: center;
    }

    .room-details-table td .room-type img {
        margin-right: 10px;
    }

    .room-details-table td .room-type p {
        margin: 0;
    }
}