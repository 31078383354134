@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.blog-page {
  max-width: 100vw;
  margin: 0 auto;
  padding: 80px 200px;
  display: flex;
  position: relative;
}

.blogs-column {
  flex: 1;
  margin-right: 20px;
}

.blog-post {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  flex-direction: column;
}

.blog-post img {
  width: 690px;
  height: 345px;
  margin-right: 20px;
}

.blog-details {
  flex: 1;
}

.blog-details h2 {
  margin-top: 0;
}

.date {
  color: #888;
}

.tags {
  margin-bottom: 10px;
}

.tag {
  display: inline-block;
  background-color: #f0f0f0;
  color: #333;
  padding: 5px 10px;
  margin-right: 5px;
}

.readmore {
  display: inline-block;
  background-color: #0c4670;
  color: white;
  padding: 10px;
  text-decoration: none;
  border-radius: 5px;
  width: 100px;
  height: 40px;
  text-align: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
}

.readmore {
  color: white;
}

.readmore:hover {
  background-color: black;
}

.search-section {

  position: relative;
  padding-left: 10px;
  
  margin-left: 20px; 
  
}
.search-bar{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adjust shadow properties as needed */
  padding: 20px;
  margin-top: 20px;
  background-color: #fff; /* Add a background color if needed */
  position: relative;
  border-radius: 8px; 
  width: 300px;

}

.search-bar input {
  padding: 10px;
 height: 50px;
  max-width: 500px; /* Increased maximum width */
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.highlighted {
  background-color: yellow;
}

.no-results {
  text-align: center;
  font-size: 18px;
  color: #888;
}
.searchbutton {
  position: absolute;
  background-color: #0c4670;
  margin-top: 20px;

  padding: 10px;
  text-decoration: none;
  border-radius: 5px;
  width: 215.2px;
  height: 50px;
  text-align: center;
  text-align: center;
  align-items: center;
  cursor: pointer;

  left: 0;
}
.searchbutton{
  color: white;
position: relative;
}
.category{
  color: #0c4670;
}
.category-section {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adjust shadow properties as needed */
  padding: 20px;
  margin-top: 20px;
  background-color: #fff; /* Add a background color if needed */
  position: relative;
  border-radius: 8px; 
  width: 300px;
}

.category-section h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  padding-top: 0;
  
  color: #0c4670;
}

.category-section ul {
  list-style-type: none;
  padding: 0;
}

.category-section li {
  margin-bottom: 10px;
}
.category-section hr {
  margin: 10px 0; /* Adjust as needed */
  border: none;
  border-top: 1px solid #ccc; /* Adjust color as needed */
}
.category-item {
  cursor: pointer;
}

.category-item:hover {
  text-decoration: underline;
}

.recent-posts-section {
  padding: 30px;
  width: 300px;
  margin-top: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add box shadow */
  border-radius: 8px; /* Optional: Add border radius for rounded corners */
}

.recent-posts-section h3 {
  margin-bottom: 10px;
  color:#0c4670;
  font-size: 24px;
  
}

.recent-post-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.recent-post-item:hover {
  background-color: #f0f0f0;
}

.recent-post-image {
  width: 70px;
  height: 70px;
  object-fit: cover;
  margin-right: 10px;
  padding: 0px;
}

.recent-post-details {
  flex: 1;
}

.recent-post-link {
  font-weight: bold;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.navigation-button {
  flex: 1;
  background-color: white;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px; /* Add margin to create a gap between buttons */
}

.navigation-button:last-child {
  margin-right: 0; /* Remove margin from the last button to avoid extra space */
}

.navigation-button img {
  height: 50px;
  width: 50px; /* Adjust the width of the thumbnail image as needed */
  margin-right: 10px;
}

/* BlogPage.css */

/* Tag cloud styles */
.tag-cloud-section {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adjust shadow properties as needed */
  padding: 20px;
  margin-top: 20px;
  background-color: #fff; /* Add a background color if needed */
  position: relative;
  border-radius: 8px; 
  width: 300px;
}
.tag-cloud-section h3{
  margin-bottom: 10px;
  color:#0c4670;
  font-size: 24px;
}
.tag-cloud {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag {
  background-color:black ;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tag:hover {
  background-color: #0c4670;
}
/* Newsletter Section */
.newsletter-section {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adjust shadow properties as needed */
  padding: 20px;
  margin-top: 20px;
  background-color: #fff; /* Add a background color if needed */
  position: relative;
  border-radius: 8px; 
  width: 300px;
}

.newsletter-section h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color:#0c4670;
}

.newsletter-section p {
  font-size: 1rem;
  margin-bottom: 20px;
}

.newsletter-form {
  display: flex;
  flex-direction: column;
}

.email-input input[type="email"] {
  width: 215.2px;
  height: 50px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.subscribe-button button {
  padding: 10px 20px;
  background-color: #0c4670;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 215.2px;
  height: 50px;
}
/* BlogPage.css */

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #0c4670;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
}

.pagination button:hover {
  background-color: #0c4670;
}
/* BlogPage.css */

/* For screens smaller than 768px (tablets and phones) */
@media (max-width: 767px) {
  .blog-page {
    padding: 80px 20px; /* Adjust padding for smaller screens */
    flex-direction: column; /* Stack sections vertically */
    align-items: center; /* Center align content */
  }

  .blogs-column {
    margin-right: 0; /* Remove right margin for blog column */
  }

  .blog-post img {
    width: 100%; /* Make the blog post images responsive */
    height: auto;
  }

  .search-section, .category-section, .recent-posts-section, .tag-cloud-section, .newsletter-section {
    width: 100%; /* Set full width for sections */
    margin-top: 10px; /* Adjust top margin for sections */
  }
}

/* For screens between 768px and 1024px (tablets) */
@media (min-width: 768px) and (max-width: 1024px) {
  .blog-page {
    padding: 80px 50px; /* Adjust padding for medium-sized screens */
  }
}
