.scp-container {
    width: 100vw;
    height: 100%;
    padding: 50px 20px; /* Reduced padding for better small-screen display */
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../public/assets/selectcat.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.scp-container h1 {
    margin: 50px 0px 20px 0px;
    color: #fff;
    font-size: 32px; /* Adjusted font size for better small-screen display */
}

.scp-container h2 {
    color: #fff;
    margin-bottom: 50px;
    font-size: 24px; /* Adjusted font size for better small-screen display */
}

.scp-contents {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center; /* Center content for small screens */
}

.scp-contents .spc-card {
    background-color: #fff;
    padding: 10px;
    width: 100%; /* Full width for small screens */
    max-width: 250px; /* Set a max-width to maintain card size */
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-bottom: 30px;
    transition: transform 0.3s ease;
}

.scp-contents .spc-card h1 {
    color: #0C4670;
    font-size: 25px;
}

.scp-contents .spc-card h2 {
    color: #0C4670;
    font-size: 20px;
}

.scp-contents .spc-card:hover {
    background-color: #0C4670;
    transform: scale(1.1);
}

.scp-card-icon {
    color: #fff;
    width: 90px;
    padding: 20px;
    background-color: #0C4670;
    border-radius: 50%;
    border: none;
    margin-bottom: 20px;
}

.scp-card-icon svg {
    width: 100%; 
    height: auto;
}

.scp-contents .spc-card:hover h1 {
    color: #fff;
}

.scp-contents .spc-card:hover h2 {
    color: #fff;
}

.scp-contents .spc-card:hover .scp-card-icon {
    color: #0C4670;
    background-color: #fff;
}

@media (min-width: 768px) {
    .scp-container {
        padding: 50px 100px; /* Adjusted padding for medium screens */
    }
}

@media (min-width: 1024px) {
    .scp-container {
        padding: 50px 200px; /* Adjusted padding for large screens */
    }
}

@media (min-width: 1200px) {
    .scp-container h1 {
        font-size: 40px; /* Restored larger font size for larger screens */
    }

    .scp-container h2 {
        font-size: 28px; /* Restored larger font size for larger screens */
    }

    .scp-contents .spc-card {
        width: 250px; /* Set fixed width for larger screens */
    }
}
