.common-section.hotel-background {
     background: linear-gradient(to top, rgba(12, 70, 112, 0.8), rgba(12, 70, 112, 0.2)),
                    url('./hotelbg.jpg') no-repeat center center;  
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
  }

  .common-section.activity-background {
    background: linear-gradient(to top, rgba(12, 70, 112, 0.8), rgba(12, 70, 112, 0.2)),
                   url('./activitybg.jpg') no-repeat center center;  
   height: 300px;
   display: flex;
   align-items: center;
   justify-content: center;
   background-size: cover;
 }

 .common-section.product-background {
  background: linear-gradient(to top, rgba(12, 70, 112, 0.8), rgba(12, 70, 112, 0.2)),
                 url('./productbg.jpg') no-repeat center center;  
 height: 300px;
 display: flex;
 align-items: center;
 justify-content: center;
 background-size: cover;
}

.common-section.blog-background {
  background: linear-gradient(to top, rgba(12, 70, 112, 0.8), rgba(12, 70, 112, 0.2)),
                 url('./blog.jpg') no-repeat center center;  
 height: 300px;
 display: flex;
 align-items: center;
 justify-content: center;
 background-size: cover;
}

.common-section.contact-background {
  background: linear-gradient(to top, rgba(12, 70, 112, 0.8), rgba(12, 70, 112, 0.2)),
                 url('./contactbg.jpg') no-repeat center center;  
 height: 300px;
 display: flex;
 align-items: center;
 justify-content: center;
 background-size: cover;
}
.common-section.rent-background {
  background: linear-gradient(to top, rgba(12, 70, 112, 0.8), rgba(12, 70, 112, 0.2)),
                 url('./rent.jpg') no-repeat center center;  
 height: 300px;
 display: flex;
 align-items: center;
 justify-content: center;
 background-size: cover;
}

 .common-section.restaurant-background {
  background: linear-gradient(to top, rgba(12, 70, 112, 0.8), rgba(12, 70, 112, 0.2)),
                 url('./res.jpg') no-repeat center center;  
 height: 300px;
 display: flex;
 align-items: center;
 justify-content: center;
 background-size: cover;
}
  .common-section h1 {
    margin-top: 15px;
    font-size: 50px;
    color: white;
  }

  .common-section{
    background: linear-gradient(to top,rgba(12, 70, 112, 0.8), rgba(12, 70, 112, 0.2)),
                    url('./tour.jpg') no-repeat center center;  
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
  }