@import "./variables.scss";
@import "./breakpoints.scss";

.register {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: url("../images/login_bg.png ") center;
  background-size: cover;

  &_logo{
    &_img{
      width: 250px;
      height: 100px;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 40%;
    padding: 40px;
    background-color: #0C4670;
    border-radius: 20px;

    @include lg {
      width: 60%
    }

    @include mm {
      width: 80%
    }

    &_form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      input {
        width: 100%;
        padding: 15px 15px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 10px;
        text-align: center;
        color: white;

        input:not(:placeholder-shown):focus {
          background: transparent; 
        }

        &::placeholder {
          color: white;
        }
      }

      label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 9px;
        cursor: pointer;
        color: white;
        font-size: 14px;

        img {
          width: 25px;
        }
      }

      button {
        margin-top: 15px;

        @include buttonStyle;
        width: 50%;

        &:hover {
          box-shadow: 0 0 1px 3px rgba(255, 255, 255);
        }
      }
    }

    a {
      text-decoration: none;
      color: white;
      font-size: 13px;
      margin-top: 10px;
      text-align: center;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
