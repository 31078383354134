.create-activities{
    width: 100vw;
    padding: 120px 90px 40px 90px;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: #f6f4f4;
}

.create-activities h1{
    color: #0C4670;
    font-size: 40px;
    margin-bottom: 30px;
}

.create-activities form{
    
}
.create-activities_step1{
    background-color: #fff;
    padding: 20px;
    border-radius: 13px;
    margin-bottom: 30px;
}

.create-activities_step1 h2{
    color: #0C4670;
    text-align: left;
    padding: 20px 0px;
}

.create-activities_step1 h3{
    color: #0C4670;
    font-size: 20px;
    margin: 40px 0px;
    text-align: left;
}

.create-activities_step1 .type-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    padding: 0px 40px;

    
}

.create-activities_step1 .type-list .type{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
    align-items: center;
    width: 180px;
    padding: 30px 10px;
    border: 1px solid grey;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease;
}


.create-activities_step1 .type-list .selected{
    border: 2px solid #0C4670;
    background-color: #0C4670;
    color: #fff;
}

.type-list .type_text{
    max-width: 400px;
}
.type-list .type_text h4{
    margin-bottom: 5px;
    
}

.create-activities_step1 .type-list .type_icon{
    font-size: 40px;
    color: #0C4670;
}
.create-activities_step1 .type-list .selected .type_icon{
    color: #fff;
}

.full{
    max-width: 700px; 
}

.location{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 10px;
    padding: 0px 40px;
}
.location input{
    border: 1px solid #000;
    border-radius: 5px;
    overflow: hidden;
    padding: 10px 20px;
    width: 300px;
}


.half{
    max-width: 700px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}





.basic p{
    font-weight: 600;
}

.basic .basic_count{
    display: flex;
    align-items: center;
    gap: 9px;
    font-size: 20px;
}


.create-activities_step2{
    background-color: #fff;
    padding: 20px 20px 50px 20px;
    border-radius: 13px;
    margin-bottom: 30px;
}

.create-activities_step2 h2{
    color: #0C4670;
    text-align: left;
    padding: 20px 0px;
}

.create-activities_step2 h3{
    color: #0C4670;
    font-size: 20px;
    margin: 40px 0px;
    text-align: left;
}

.amenities{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0px 40px;
}
.amenities .facility{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 90px;
    border: 1px solid grey;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s ease;
}

.amenities .facility .facility_icon{
    font-size: 30px;
    color: #0C4670;
}
.create-activities_step2 .amenities .facility p{
    font-weight: 600;
}

.create-activities_step2 .amenities .facility:hover{
    
    background-color: #0C4670;
    color: #fff;
}
.create-activities_step2 .amenities .facility:hover .facility_icon{
    color: #fff;
}

.create-activities_step2 .amenities .selected{
    background-color: #0C4670;
    color: #fff;
}

.create-activities_step2 .amenities .selected .facility_icon{
    color: #fff;
}

.create-activities_step2 .photos{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 0px 40px;
}

.create-activities_step2 .photos .photo{
    position: relative;
     width: 250px;
     height: 150px;
    cursor: move;
}

.create-activities_step2 .photos .photo img{
    width: 100%;
     height: 100%;
}

.create-activities_step2 .photos .photo button{
    position: absolute;
    right: 0;
    top: 0;
    padding: 3px;
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 20px;
    cursor: pointer;
}
.create-activities_step2 .photos label{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px dashed grey;
}
.create-activities_step2 .photos label .icon{
    font-size: 60px;
    color:#0C4670;
}
.create-activities_step2 .photos label p{
    font-weight: 600;
    text-align: center;
}
.create-activities_step2 .photos .together{
    width: 250px;
    height: 150px;
}
.create-activities_step2 .photos .alone{
    padding: 40px 100px;
    border-radius: 10px;
}

.create-activities_step2 .photos .photo button:hover{
    color: red;
}

/* .create-listing_step2 input{
    border: 1px solid red;
    padding: 15px 30px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
} */
.create-activities_step2 .description{
    text-align: left;
    padding: 0px 40px;
}
.create-activities_step2 .description p{
    font-weight: 700;
    margin: 20px 0 10px;

}
.create-activities_step2 .description input,textarea{
    border: 1px solid grey;
    padding: 20px 30px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    width: 600px;
}
.create-activities_step2 .description span{
    font-size: 25px;
   font-weight: 700;
    margin-right: 20px;
}
.create-activities_step2 .description .price{
    width: 200px;
}

.create-listing_step2 .description-box .uploaded-image-container{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}
.create-listing_step2 .description-box .uploaded-image-container .uploaded-image{
    display: flex;
    flex-direction: column;
}

.create-listing_step2 .description-box .uploaded-image-container .uploaded-image img{
    width: 200px;
    height: 150px;
}

.create-listing_step2 .description-box h3 span{
    color: #7d7d7d;
    font-size: 12px;
}

.submit_btn{
    margin:40px 0px 50px 0px;
    padding:  20px;
    background-color: #0C4670;
    color: #fff;
    border-radius: 10px;
    border: none;
    font-size: 20px;
    font-weight: 700;
}
.submit_btn:hover{
    cursor: pointer;
    background-color: #fff;
    color: #0C4670;
    border: 1px solid #0C4670;
}






/* vehicl rent */
.create-activities_step2 .basic-deatils{
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-left: 50px;
}
.create-activities_step2 .basic-deatils .vehicle-radio{
    display: flex;
    gap: 60px;
    
}

.create-activities_step2 .vehicle-radio .option-select{
    
    gap: 30px;
}
.create-activities_step2 .vehicle-radio .option-select label{
    display: flex;
    gap: 10px;
}

.create-activities_step2 .basic-deatils .basics{
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    
}

.create-activities_step2 .basic-deatils .basics .basic{
    display: flex;
     align-items: center;
    gap: 30px;
     padding: 15px;
    border: 1px solid grey;
    border-radius: 10px;
}

.create-activities_step2 .extras{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0px 40px;
}
.create-activities_step2 .extras .extra{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid grey;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s ease;
}

.create-activities_step2 h3 span{
    color: grey;
    font-size: 15px;
    font-weight: 300;
}

.create-activities_step2 .extras .extra p{
    font-weight: 600;
}

.create-activities_step2 .extras .extra:hover{
    
    background-color: #0C4670;
    color: #fff;
}

.create-activities_step2 .extras .selected{
    background-color: #0C4670;
    color: #fff;
}
.create-activities_step2 .rental-details{
    padding: 0px 40px;
}

.create-activities_step2 .rental-details tr{
    text-align: left;
}

.create-activities_step2 .rental-details td{
    padding: 20px 0px;
   width: 50px;
}

.create-activities_step2 .rental-details td input{
    border: 1px solid grey;
    padding: 15px 15px;
    border-radius: 5px;
    width:300px;
    font-size: 16px;
    font-weight: 600;
    
}
.input-group1, .input-group2 ,.input-group3 , .input-group4, .input-group5 {
    position: relative;
    margin-left: 30px;
}

.input-group1::before, .input-group3::before {
    content: "Rs.";
    position: absolute;
    left: 10px; /* Adjust based on the padding-left value */
    top: 40%;
    transform: translateY(-50%);
    color: #555; /* Optional: Adjust color as needed */
}

.input-group2::after {
    content: "Km";
    position: relative;
    right: 50px; /* Adjust based on the padding-right value */
    top: 50%;
    transform: translateY(-50%);
    color: #555; /* Optional: Adjust color as needed */
}
.input-group4::after, .input-group5::after {
    content: "Day(s)";
    position: relative;
    right: 100px; /* Adjust based on the padding-right value */
    top: 50%;
    transform: translateY(-50%);
    color: #555; /* Optional: Adjust color as needed */
}

/* Responsive Media Queries */
@media (max-width: 1200px) {
    .create-activities {
        padding: 100px 50px 30px 50px;
    }

    .location,
    .basics {
        padding: 0 20px;
    }
}

@media (max-width: 992px) {
    .create-activities {
        padding: 80px 30px 20px 30px;
    }

    .description-box,
    .add-location,
    .add-photos,
    .res-view,
    .res-time,
    .dining-style,
    .cuisines-style,
    .menu-photo,
    .res-offer,
    .create-activities_step1,
    .create-activities_step2 {
        padding: 15px;
    }

    .half {
        grid-template-columns: 1fr;
    }

    .type-list .type {
        width: 100%;
    }

    .heading img {
        height: 80px;
        width: 80px;
    }

    .location input {
        width: 100%;
    }

    .create-activities_step2 .description input,
    textarea {
        width: 100%;
    }

    .create-activities_step2 .description .price {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .create-activities {
        padding: 60px 20px 10px 20px;
    }

    .description-box,
    .add-location,
    .add-photos,
    .res-view,
    .res-time,
    .dining-style,
    .cuisines-style,
    .menu-photo,
    .res-offer,
    .create-activities_step1,
    .create-activities_step2 {
        padding: 10px;
    }

    .progress-icons {
        flex-direction: column;
        gap: 10px;
    }

    .progress-icon::before {
        display: none;
    }

    .location input {
        width: 100%;
    }

    .create-activities_step2 .description input,
    textarea {
        width: 100%;
    }

    .create-activities_step2 .description .price {
        width: 100%;
    }
}

@media (max-width: 576px) {
    .create-activities {
        padding: 40px 10px 5px 10px;
    }

    .heading h3 {
        font-size: 20px;
    }

    .heading img {
        height: 60px;
        width: 60px;
    }

    .description-box,
    .add-location,
    .add-photos,
    .res-view,
    .res-time,
    .dining-style,
    .cuisines-style,
    .menu-photo,
    .res-offer,
    .create-activities_step1,
    .create-activities_step2 {
        padding: 5px;
    }

    .progress-icons {
        flex-direction: column;
        gap: 5px;
    }

    .progress-icon::before {
        display: none;
    }

    .location input {
        width: 100%;
    }

    .create-activities_step2 .description input,
    textarea {
        width: 100%;
    }

    .create-activities_step2 .description .price {
        width: 100%;
    }
}

@media (max-width: 400px) {
    .create-activities {
        padding: 20px 5px 2px 5px;
    }
    .create-activities_step2 .rental-details td input {
        border: 1px solid grey;
        padding: 15px 15px;
        border-radius: 5px;
        width: 100px;
        font-size: 16px;
        font-weight: 600;
    }
    .heading h3 {
        font-size: 18px;
    }

    .heading img {
        height: 50px;
        width: 50px;
        padding-top: 10px;
    }

    .description-box,
    .add-location,
    .add-photos,
    .res-view,
    .res-time,
    .dining-style,
    .cuisines-style,
    .menu-photo,
    .res-offer,
    .create-activities_step1,
    .create-activities_step2 {
     
    }

    .progress-icons {
        flex-direction: column;
        gap: 2px;
    }

    .progress-icon::before {
        display: none;
    }

    .location input {
        width: 100%;
    }

    .create-activities_step2 .description input,
    textarea {
        width: 100%;
    }

    .create-activities_step2 .description .price {
        width: 100%;
    }
}

