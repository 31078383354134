.listing-details .special{
    padding-left: 0px;
    
}

.offers-hotel,.menu-items,.cusdines{
border: 1px solid #ccc;
padding: 20px;
margin: 20px 0;
background-color: #f9f9f9;
border-radius: 10px;}

.offers-hotel .amenities{
    margin-top: 10px;
    padding-left: 0px;
}

.cusdines {
    display: flex;
    gap: 10px; /* Optional: Adjust the gap between the boxes */
  }
  
  .box {
    flex: 1; /* Optional: Adjust the flex-grow to control the size of the boxes */
    padding: 10px; /* Optional: Adjust the padding inside the boxes */
    border: 1px solid #ccc; /* Optional: Adjust the border style */
    border-radius: 5px; /* Optional: Adjust the border radius for rounded corners */
    background-color: #f9f9f9; /* Optional: Adjust the background color */
  }
  .photos {
    display: -ms-grid;
    grid-auto-flow: dense;
    column-count: 4;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 10rem);
    grid-gap: 4px;
  }
  
  .main-photo {
    width: 100px;
    flex: 3;
    max-height: 400px; /* Adjust the height of the main image as needed */
    object-fit: cover; /* Ensure the image covers the container */
  }
  
  .sub-photos {
    
    display: grid;
    grid-template-rows: repeat(2, 1fr); 
    grid-template-columns: repeat(2, 1fr); /* Each column contains two sub-photos */
    gap: 13px; /* Adjust the gap between photos */
  }
  
  .sub-photo-wrapper {
    position: relative;
  }
  
  .sub-photo {
    width: 100%; 
    height: 193px;/* Ensure each photo fills its container */
  }
  
  .more-photos-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
    z-index: 10; /* Ensure it is above the image */
  }
 .lightbox{
    width: 100px;
    height: 100px;
 }

.rent-offer{
  
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.rentings{
  background: linear-gradient(to right, #4174c7, #20346f);
  flex: 1 1 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
}

.amenities {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.facility {
  display: flex;
  align-items: center;
  margin: 5px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.facility_icon {
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.facility_icon svg {
  margin-right: 5px;
}

.facility p {
  margin: 0;
}

.callview{
  background: linear-gradient(to right, #6cff5f, #9afe7b);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    display: inline-block;
   

}

.pro-photos img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  
  width: 350px;
  height: 350px;
}
.pro-title{
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
  color:#0c4670 ;
  
}
/* Media query for devices with a max width of 768px */
@media only screen and (max-width: 768px) {
  .pro-photos img {
    width: 300px;
    height: 300px;
  }

  .pro-title {
    margin-top: 70px;
    font-size: 16px;
  }
}

/* Media query for devices with a max width of 480px */
@media only screen and (max-width: 480px) {
  .pro-photos img {
    width: 100%;
    height: auto;
  }

  .pro-title {
    font-size: 14px;
  }
}