.listing-container{
  display: flex;
  justify-content: right;
  
}

.listing-container .listing-card, .pro-listing-card {
    position: relative;
    
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    
   
}

.listing-card:hover, .pro-listing-card:hover {
    background-color: #eaf5fb;
    
    border: 2px solid #007bff; 
    border-radius: 10px;
}

.listing-card .slider-container{
    width: 250px;
    overflow: hidden;
    
}
.listing-card .slider-container .slider{
    display: flex;
    transition: transform 0.5s ease;
}

.listing-card .slider-container .slider .slide{
    position: relative;
    flex: 0 0 100%;
    width: 100%;
    height: 220px;
    display: flex;
    align-items: center;
}

.listing-card .slider-container .slider .slide img{
    width: 100%;
    height: 100%;
    filter: brightness(85%);
    border-radius: 10px 10px 0px 0px;
   
}

.listing-card .slider-container .slider .slide .prev-button,
.listing-card .slider-container .slider .slide .next-button{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 6px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
}

.listing-card .slider-container .slider .slide .prev-button:hover,
.listing-card .slider-container .slider .slide .next-button:hover{
    background-color: white;
}

.listing-card .slider-container .slider .slide .prev-button{
    left: 10px;
}

.listing-card .slider-container .slider .slide .next-button{
    right: 10px;
}

.listing-container .listing-card h3,p, .pro-listing-card h3,p{
   
    
}

.listing-container .listing-card h3, .pro-listing-card h3 {
    text-transform: uppercase;
    font-size: 18px;
    
  }

  .listing-container .listing-card p, .pro-listing-card p {
    
    font-size: 15px;
    color: rgb(60, 60, 60);
  }

  .listing-card span, .pro-listing-card span {
    font-size: 14px;
    color: #0C4670;
  }

  .listing-card .favorite {
    position: absolute;
    right: 20px;
    top: 20px;
    border: none;
    font-size: 20px;
    cursor: pointer;
    z-index: 999;
    background: none;
  }
  .list-details, .pro-list{
    background-color: #eaf5fb;
    border-radius: 0px 0px 10px 10px;
    padding-bottom: 10px;
    max-width: 250px;
    margin: 0px;
    
  }
  .list-details .time{
    background:#0C4670;
    border-radius: 4px;
    color: gray;
    display: block;
    width: 70px;
    cursor: pointer;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 4px;
    position: relative;
    text-align: center;
    text-decoration: none;
    height: 2rem;
    
   }
.time-details{
    display: flex;
}
.time-details .to{
    margin:5px 5px;
    color: black;
    
}

.blink {
  animation: blink 3s infinite;
  color: red; /* Initial color */
}

@keyframes blink {
  0% { opacity: 1; color: red; }
  50% { opacity: 0; color: red; }
  100% { opacity: 1; color: red; }
}
  .placeview {
    background: linear-gradient(to right, #6cff5f, #9afe7b);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    display: inline-block;
    margin-left: 0px;
  }
 /* CSS */
.listing-container .listing-card .list-details .title, .pro-listing-card.list-details.title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
 

  .pro-listing-card img{
    width: 250px;
    height: 255.33px;
    border-radius: 10px 10px 0px 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #05a13e;
    color: white;
    border-radius: 50%;
    padding: 10px 12px;
    cursor: pointer;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    transition: transform 0.3s ease;
  }
  
  .whatsapp-icon:hover {
    transform: scale(1.1);
  }