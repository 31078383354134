$pinkred: #fff;
$blue: #24355A;
$lightgrey: #F7F8F8;
$grey: #bdb9b9;
$darkgrey: #969393;


@mixin shadow {
  box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.2);
}

@mixin buttonStyle {
  padding: 10px 15px;
  background-color: $pinkred;
  border: none;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s ease;
  color: #0C4670;
  border-radius: 10px;
}

:export {
  pinkred: $pinkred;
  blue: $blue;
  lightgrey: $lightgrey;
  grey: $grey;
  darkgrey: $darkgrey;
}
