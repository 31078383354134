@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.abcontainer {
    width: 100%;
    padding: 40px 20px; /* Reduced padding for smaller screens */
    margin: 40px 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.about-section {
    display: flex;
    flex-direction: column; /* Default to column layout for smaller screens */
    gap: 30px; /* Reduced gap for smaller screens */
}

.about-text {
    text-align: left;
}

.about-text p {
    color: #008080;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}

.about-text h1 {
    color: #0C4670;
    font-weight: bold;
    font-size: 30px; /* Adjusted font size for smaller screens */
    margin-bottom: 20px;
}

.about-text h4 {
    color: grey;
    font-weight: 500;
    font-size: 16px; /* Adjusted font size for smaller screens */
    margin-bottom: 20px;
}

.about-call {
    display: flex;
    flex-direction: column;
}

.icon-bg {
    display: flex;
    margin-bottom: 20px;
    gap: 10px;
}

.icon-bg p {
    color: grey;
    padding-top: 10px;
}

.icon-circle {
    background-color: #0C4670;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 20px;
}

.icon {
    color: #fff;
    margin-top: -12px;
    margin-bottom: 20px;
    margin-left: -12px;
}

.about-button {
    background-color: #0C4670;
    padding: 15px; /* Reduced padding for smaller screens */
    display: flex;
    justify-content: space-between;
    width: 180px; /* Adjusted width for smaller screens */
    height: 50px; /* Adjusted height for smaller screens */
    border-radius: 5px;
    text-align: center;
    align-items: center;
    cursor: pointer;
}

.about-button h3 {
    color: #fff;
    font-size: 16px; /* Adjusted font size for smaller screens */
    white-space: nowrap; /* Prevent text wrapping */

}

.about-section .about-text .about-button .arrow {
    color: #fff;
}

.about-img {
    padding: 0 15px 15px 15px; /* Adjusted padding for smaller screens */
    position: relative;
}

.about-img .img-before {
    content: '';
    position: absolute;
    top: 20px; /* Adjusted positioning for smaller screens */
    right: 0;
    left: 30px; /* Adjusted positioning for smaller screens */
    bottom: 0;
    height: 400px; /* Adjusted size for smaller screens */
    width: 280px; /* Adjusted size for smaller screens */
    background-color: #0C4670;
}

.about-img img {
    position: relative;
    z-index: 1;
    transition: transform 0.7s ease;
}

.img {
    width: 280px; /* Adjusted size for smaller screens */
    height: 400px; /* Adjusted size for smaller screens */
}

.about-img img:hover {
    transform: scale(0.9); /* Zoom out effect */
}

/* Media Queries */
@media (min-width: 768px) {
    .abcontainer {
        padding: 40px 50px; /* Adjusted padding for medium screens */
    }
    
    .about-section {
        flex-direction: row; /* Change to row layout for medium screens */
        gap: 50px; /* Adjusted gap for medium screens */
    }

    .about-text h1 {
        font-size: 35px; /* Adjusted font size for medium screens */
    }

    .about-text h4 {
        font-size: 17px; /* Adjusted font size for medium screens */
    }

    .about-button {
        padding: 20px; /* Adjusted padding for medium screens */
        width: 200px; /* Adjusted width for medium screens */
        height: 55px; /* Adjusted height for medium screens */
    }

    .about-img .img-before {
        top: 25px; /* Adjusted positioning for medium screens */
        left: 35px; /* Adjusted positioning for medium screens */
        height: 440px; /* Adjusted size for medium screens */
        width: 300px; /* Adjusted size for medium screens */
    }

    .img {
        width: 300px; /* Adjusted size for medium screens */
        height: 440px; /* Adjusted size for medium screens */
    }
}

@media (min-width: 1024px) {
    .abcontainer {
        padding: 40px 200px; /* Adjusted padding for large screens */
    }
    
    .about-section {
        gap: 70px; /* Adjusted gap for large screens */
    }

    .about-text h1 {
        font-size: 40px; /* Adjusted font size for large screens */
    }

    .about-text h4 {
        font-size: 18px; /* Adjusted font size for large screens */
    }

    .about-button {
        padding: 30px; /* Adjusted padding for large screens */
        width: 220px; /* Adjusted width for large screens */
        height: 60px; /* Adjusted height for large screens */
    }

    .about-img .img-before {
        top: 30px; /* Adjusted positioning for large screens */
        left: 45px; /* Adjusted positioning for large screens */
        height: 480px; /* Adjusted size for large screens */
        width: 350px; /* Adjusted size for large screens */
    }

    .img {
        width: 350px; /* Adjusted size for large screens */
        height: 480px; /* Adjusted size for large screens */
    }
}
