
    .slide {
      position: relative;
      width: 100vw;
      
      margin: auto;
    }
    
    .slide-container {
      position: relative;
      width: 100%;
      height: 100vh; /* Set a fixed height for the slide */
      overflow: hidden;
    }
    
    .slide-image {
      width: 100%;
      height: 100%;
       /* Ensures the image covers the entire container */
    }
    .slide-container::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3));
      z-index: 1;
    }
    
    .static-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.slide-text {
  font-size: 40px;
  color: white;
  text-align: center;
  width: 800px;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}
    
    

    
    
    .slick-dots li button:before {
      color: #fff; 
      font-size: 10px;
     
      
    }
    
    .slick-dots li.slick-active button:before {
      font-size: 15px;
      color: #fff; 
      
    }

.navbar_search {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  padding: 40px 40px;
  background-color: #fff;
  border-radius: 10px;
  color: #fff;
}
    
    

/* .navbar_search {
  border: 1px solid grey;
  border-radius: 30px;
  background-color: #fff;
  height: 60px;
  width: 400px;
  padding: 10px 20px;
  color: #fff;
  display: flex;
  overflow: hidden;
}
  input {
    flex: 1;
  height: 30px;
  border: none;
  font-size: 16px;
  outline: none;
  padding: 10px;
  } */

  @media (max-width: 768px) {
.navbar_search{
display:none;
}
    .slide-container {
      height: 70vh;
    }
  
    .slide-text {
      font-size: 6vw;
    }
  }
  
  @media (max-width: 480px) {
.navbar_search{
display:none;
}
    .slide-container {
      height: 50vh;
    }
  
    .slide-text {
      padding: 0px;
      margin-top: 45px;
      width: 300px;
    }
  }
  

  



