* {
  margin: 0;
  text-decoration: none;
  
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
}


*, *::before, *::after {
  box-sizing: inherit;
}

html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  overflow-x: hidden;
}


@media (max-width: 768px) {
  body {
    overflow-x: hidden; /* Prevents horizontal scrolling on mobile devices */
  }
}
