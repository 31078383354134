.prcontainer{
    background: linear-gradient(rgba(12, 70, 112, 0.5), rgba(12, 70, 112, 0.5)),
    url("../../public/assets/productbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 500px;
    padding: 20px 200px;
    background-attachment: fixed;
    display: flex; 
    justify-content: flex-end; 
    align-items: center; 
    text-align: right;
}

.product-section{
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.product-section h1{
    font-size: 55px;
    color: #fff;
   
}

.product-section p{
    font-size: 20px;
    margin-bottom: 10px;
    color: #fff;
}

.product-button{
    background-color: #fff;
    padding: 30px;
    display: flex; 
    justify-content: space-between;
    width: 220px;
    height: 60px;
    border-radius: 8px;
    align-items: center;
    cursor: pointer;
}

.product-button h3{
    color: #0C4670 ;
}
.prcontainer .product-section .product-button .arrow{
    color: #0C4670;
} 
@media screen and (max-width: 768px) {
    .product-section h1 {
      font-size: 35px; /* Adjusted font size */
    }
  
    .product-section p {
      font-size: 16px; /* Adjusted font size */
    }
  
    .product-button {
      width: 180px; /* Adjusted width */
      height: 45px; /* Adjusted height */
    }
  
    .product-button h3 {
      font-size: 14px; /* Adjusted font size */
    }
  }
  
  @media screen and (max-width: 480px) {
    .prcontainer {
      padding: 20px; /* Adjusted padding */
    }
  
    .product-section h1 {
      font-size: 30px; /* Adjusted font size */
    }
  
    .product-section p {
      font-size: 14px; /* Adjusted font size */
    }
  
    .product-button {
      width: 160px; /* Adjusted width */
      height: 40px; /* Adjusted height */
      padding: 15px; /* Adjusted padding */
    }
  
    .product-button h3 {
      font-size: 12px; /* Adjusted font size */
    }
  }