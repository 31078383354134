@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.contact-us-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 80px 200px;
    background-color: #f5f5f5;
  }
  
  .contact-details, .contact-form {
    flex: 1;
    min-width: 300px;
    margin: 10px;
    padding: 20px;

    
  }
  .contact-details h2{
    color: #0C4670;
  }
  
  .contact-details h2, .contact-form h2 {
    margin-bottom: 20px;

  }
  
  .contact-details p {
    margin: 10px 0;
  }
  
  .contact-form .form-group {
    margin-bottom: 15px;
  }
  .contact-form h2{
    color: #0C4670;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .contact-form input, .contact-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact-form button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #0C4670;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: black;
  }
  .form-group input::placeholder,
.form-group textarea::placeholder {
  font-size: 12px; /* Adjust the font size as needed */
  color: #aaa; 
  /* Optionally adjust the color */
}

.detail{
    font-size: 12px;
}

.contact-details.icon{
  size: 20px;
}
.icon-detail-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adjust margin as needed */
}

.icon-background {
  width: 50px;
  height: 50px;
  background-color: #0C4670;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px; /* Adjust margin as needed */
}

.white-icon {
  color: white;
  font-size: 20px;
}
.form-group input{
  height: 50px;
}
/* ContactUs.css */

/* For screens smaller than 768px (tablets and phones) */
@media (max-width: 767px) {
  .contact-us-container {
    padding: 40px 20px; /* Adjust padding for smaller screens */
  }

  .contact-details, .contact-form {
    flex: 1;
    min-width: unset; /* Remove minimum width for better responsiveness */
    margin: 10px 0; /* Adjust margin for smaller screens */
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .contact-form input, .contact-form textarea {
    padding: 8px; /* Adjust input and textarea padding */
  }

  .contact-form button {
    padding: 8px 16px; /* Adjust button padding */
  }
}

/* For screens between 768px and 1024px (tablets) */
@media (min-width: 768px) and (max-width: 1024px) {
  .contact-us-container {
    padding: 60px 100px; /* Adjust padding for medium-sized screens */
  }
}
