
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.create-restaurant{
    width: 100vw;
    padding: 120px 90px 40px 90px;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: #f6f4f4;
}

.heading {
    
    display: flex;
   text-align: left;
    align-items: left;
    background-color: #eaf5fb;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    
}
.heading h3{
    color: #0C4670;
    font-size: 25px;
}
.heading p{
    padding-left: 0;
    color: #000;
}
.heading img{
    
    height: 110px;
    width: 110px;
}

.res-highlight{
    color: red;
}

.create-restaurant form{
    
}
.description-box ,.add-location,.add-photos,.res-view,.res-time,.dining-style,.cuisines-style,.menu-photo,.res-offer{
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
  }
  
  .description-box h3 {
    margin-top: 0;
  }
  
  .description-box p {
    margin-bottom: 5px;
  }
  
  .description-box input,
  .description-box textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .description-box-1 input,
  .description-box-1 textarea {
    width: 100%;
    padding: 10px;
    height: 400px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  .step-heading {
    position: relative;
    margin-top: 20px;
    display: flex;
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 20px 20px;
    margin-bottom: 20px;
    
  }
  
  .step-number {
    padding-top: 10px;
    position: absolute;
    top: -20px;
    left: 20px;
    background-color: #0C4670;
    padding: 5px 10px;
    border-radius: 8px;
    animation: blink 1s infinite alternate; /* Apply blink animation */
  }
  
  .step-description {
    margin-top: 10px;
  }
  
  @keyframes blink {
    from {
      color: white; /* Start color */
    }
    to {
      color: red; /* End color */
    }
  }
  
  .progress-bar {
    width: 100%;
    height: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .progress {
    height: 100%;
    background-color: #007bff;
    border-radius: 10px;
  }
  .progress-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; /* Adjust margin as needed */
    position: relative;
  }
  
  .progress-icon {
    font-size: 24px;
    color: #4caf50; /* Adjust color as needed */
    position: relative;
  }
  
  .progress-icon::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: calc(100% - 20px); /* Adjust the width based on your layout */
    height: 2px; /* Adjust line thickness */
    background-color: #4caf50; /* Adjust line color */
    z-index: -1;
  }
  
  .progress-icon:first-child::before {
    left: 50%;
  }
  
  .progress-icon:last-child::before {
    right: 50%;
  }
  
     
  
.create-activities_step1 {
    border: 1px solid #ccc; /* Border color */
    border-radius: 8px; /* Rounded corners */
    margin-top: 70px;
    padding: 20px; /* Space inside the border */
    margin-bottom: 20px; /* Space below the box */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow for better visibility */
  }

.create-activities_step1 h2{
    color: #0C4670;
    text-align: left;
    padding: 20px 0px;
}

.create-activities_step1 h3{
    color: #0C4670;
    font-size: 20px;
    margin: 40px 0px;
    text-align: left;
}

.create-activities_step1 .type-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 0px 40px;
    
}

.create-activities_step1 .type-list .type{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
    align-items: center;
    width: 180px;
    padding: 30px 10px;
    border: 1px solid grey;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease;
}


.create-activities_step1 .type-list .selected{
    border: 2px solid #0C4670;
    background-color: #0C4670;
    color: #fff;
}

.type-list .type_text{
    max-width: 400px;
}
.type-list .type_text h4{
    margin-bottom: 5px;
    
}

.create-activities_step1 .type-list .type_icon{
    font-size: 40px;
    color: #0C4670;
}
.create-activities_step1 .type-list .selected .type_icon{
    color: #fff;
}

.full{
    max-width: 700px; 
}

.location{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 10px;
    padding: 0px 40px;
}
.location input{
    border: 1px solid #000;
    border-radius: 5px;
    overflow: hidden;
    padding: 10px 20px;
}


.half{
    max-width: 700px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.basics{
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding: 0px 40px;
}

.basic{
    display: flex;
     align-items: center;
      gap: 30px;
     padding: 15px;
        border: 1px solid grey;
    border-radius: 10px;
}

.basic p{
    font-weight: 600;
}

.basic .basic_count{
    display: flex;
    align-items: center;
    gap: 9px;
    font-size: 20px;
}


.create-activities_step2{
    background-color: #fff;
    padding: 20px 20px 50px 20px;
    border-radius: 13px;
    margin-bottom: 30px;
}

.create-activities_step2 h2{
    color: #0C4670;
    text-align: left;
    padding: 20px 0px;
}

.create-activities_step2 h3{
    color: #0C4670;
    font-size: 20px;
    margin: 40px 0px;
    text-align: left;
}

.amenities{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0px 40px;
}


.amenities .facility{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 90px;
    border: 1px solid grey;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s ease;
}
.amenities .cuisine{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    border: 1px solid grey;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s ease;
}
.create-listing_step2 .amenities .cuisine p{
    font-weight: 600;
}
.create-listing_step2 .amenities .cuisine:hover{
    
    background-color: #0C4670;
    color: #fff;
}

.amenities .facility .facility_icon{
    font-size: 30px;
    color: #0C4670;
}
.create-listing_step2 .amenities .facility p{
    font-weight: 600;
}

.create-listing_step2 .amenities .facility:hover{
    
    background-color: #0C4670;
    color: #fff;
}
.create-listings_step2 .amenities .facility:hover .facility_icon{
    color: #fff;
}

.create-listing_step2 .amenities .facility .selected{
    background-color: #0C4670;
    color: #fff;
}

.create-listing_step2 .amenities .selected{
    background-color: #0C4670;
    color: #fff;
}

.create-listing_step2 .amenities .selected .facility_icon{
    color: #fff;
}

.create-activities_step1 .photos{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 0px 40px;
}

.create-activities_step1 .photos .photo{
    position: relative;
     width: 250px;
     height: 150px;
    cursor: move;
}

.create-activities_step1 .photos .photo img{
    width: 100%;
     height: 100%;
}

.create-activities_step1 .photos .photo button{
    position: absolute;
    right: 0;
    top: 0;
    padding: 3px;
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 20px;
    cursor: pointer;
}
.create-activities_step1 .photos label{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px dashed grey;
}
.create-activities_step1 .photos label .icon{
    font-size: 60px;
    color:#0C4670;
}
.create-activities_step1 .photos label p{
    font-weight: 600;
    text-align: center;
}
.create-activities_step1 .photos .together{
    width: 250px;
    height: 150px;
}
.create-activities_step1 .photos .alone{
    padding: 40px 100px;
    border-radius: 10px;
}

.create-activities_step1 .photos .photo button:hover{
    color: red;
}

/* .create-listing_step2 input{
    border: 1px solid red;
    padding: 15px 30px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
} */
.create-activities_step1 .description{
    text-align: left;
    padding: 0px 40px;
}
.create-activities_step1 .description p{
    
    margin: 20px 0 10px;

}
.create-activities_step1 .description input,textarea{
    border: 1px solid grey;
    padding: 20px 30px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    width: 600px;
}
.create-activities_step1 .description span{
    font-size: 25px;
   font-weight: 700;
    margin-right: 20px;
}
.create-activities_step1 .description .price{
    width: 200px;
}

.submit_btn{
    margin:40px 0px 50px 0px;
    padding:  20px;
    background-color: #0C4670;
    color: #fff;
    border-radius: 10px;
    border: none;
    font-size: 20px;
    font-weight: 700;
}
.submit_btn:hover{
    cursor: pointer;
    background-color: #fff;
    color: #0C4670;
    border: 1px solid #0C4670;
}
.create-restaurant .create-activities_step2 .photos{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 0px 40px;
}

.create-restaurant .create-activities_step2 .photos .photo{
    position: relative;
     width: 250px;
     height: 150px;
    cursor: move;
}

.create-restaurant .create-activities_step2 .photos .photo img{
    width: 100%;
     height: 100%;
}

.create-restaurant .create-activities_step2 .photos .photo button{
    position: absolute;
    right: 0;
    top: 0;
    padding: 3px;
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 20px;
    cursor: pointer;
}
.create-restaurant .create-activities_step2 .photos label{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px dashed grey;
}
.create-restaurant .create-activities_step2 .photos label .icon{
    font-size: 60px;
    color:#0C4670;
}
.create-restaurant .create-activities_step2 .photos label p{
    font-weight: 600;
    text-align: center;
}
.create-restaurant .create-activities_step2 .photos .together{
    width: 250px;
    height: 150px;
}
.create-restaurant .create-activities_step2 .photos .alone{
    padding: 40px 100px;
    border-radius: 10px;
}

.create-restaurant .create-activities_step2 .photos .photo button:hover{
    color: red;
}

.create-restaurant .create-activities_step1 .place-view {
    padding: 0px 40px;
    justify-content: left;
    display: flex;
  }
  
.create-restaurant .create-activities_step1  .place-view select {
    width: 70%;
    padding: 10px;
    margin-bottom: 10px;
    
    
    font-size: 16px;
  }

  .create-restaurant .create-activities_step1 .hotel-times {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    padding: 0px 40px;
  }
  
  .time-input {
    display: flex;
    text-align: left;
    flex-direction: column;
    width: 20%;

  }
  
  .time-input label {
    margin-bottom: 0.5rem;
  }
  
  .time-input input {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .time-input select {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    
  }

  

  .create-listing_step2 .photos{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 0px 40px;
}

.create-listing_step2 .photos .photo{
    position: relative;
     width: 250px;
     height: 150px;
    cursor: move;
}

.create-listing_step2 .photos .photo img{
    width: 100%;
     height: 100%;
}

.create-listing_step2 .photos .photo button{
    position: absolute;
    right: 0;
    top: 0;
    padding: 3px;
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 20px;
    cursor: pointer;
}
.create-listing_step2 .photos label{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px dashed grey;
}
.create-listing_step2 .photos label .icon{
    font-size: 60px;
    color:#0C4670;
}
.create-listing_step2 .photos label p{
    font-weight: 600;
    text-align: center;
}
.create-listing_step2 .photos .together{
    width: 250px;
    height: 150px;
}
.create-listing_step2 .photos .alone{
    padding: 40px 100px;
    border-radius: 10px;
}

.create-listing_step2 .photos .photo button:hover{
    color: red;
}
.create-listing_step2 .add-photos .uploaded-image-container{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.create-listing_step2 .add-photos .uploaded-image-container .uploaded-image{
    display: flex;
    flex-direction: column;
}

.create-listing_step2 .add-photos .uploaded-image-container .uploaded-image img{
    width: 200px;
    height: 150px;
}

.create-listing_step2 .add-photos input{
    border: 1px solid grey;
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 10px;
}


.create-activities_step1 .add-photos .uploaded-image-container{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.create-activities_step1 .add-photos .uploaded-image-container .uploaded-image{
    display: flex;
    flex-direction: column;
}

.create-activities_step1 .add-photos .uploaded-image-container .uploaded-image img{
    width: 200px;
    height: 150px;
}

.create-activities_step1 .add-photos input{
    border: 1px solid grey;
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 10px;
}

.create-restaurant .create-activities_step1 .place-view {
    padding: 0px 40px;
    justify-content: left;
    display: flex;
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
    .create-restaurant {
        padding: 100px 50px 30px 50px;
    }

    .location,
    .basics {
        padding: 0 20px;
    }

    .create-activities_step1 .description input, 
    .create-activities_step1 .description textarea {
        border: 1px solid grey;
        padding: 20px 30px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 600;
    }

    .create-restaurant .create-activities_step1 .place-view {
        padding: 0px 20px;
    }
}

@media (max-width: 992px) {
    .create-restaurant {
        padding: 80px 30px 20px 30px;
    }

    .description-box,
    .add-location,
    .add-photos,
    .res-view,
    .res-time,
    .dining-style,
    .cuisines-style,
    .menu-photo,
    .res-offer,
    .create-activities_step1,
    .create-activities_step2 {
        padding: 15px;
    }

    .half {
        grid-template-columns: 1fr;
    }

    .type-list .type {
        width: 100%;
    }

    .heading img {
        height: 80px;
        width: 80px;
    }

    .create-activities_step1 .description input, 
    .create-activities_step1 .description textarea {
        border: 1px solid grey;
        padding: 20px 30px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 600;
    }

    .create-restaurant .create-activities_step1 .place-view {
        padding: 0px 15px;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width: 768px) {
    .create-restaurant {
        padding: 60px 20px 10px 20px;
    }

    .description-box,
    .add-location,
    .add-photos,
    .res-view,
    .res-time,
    .dining-style,
    .cuisines-style,
    .menu-photo,
    .res-offer,
    .create-activities_step1,
    .create-activities_step2 {
        padding: 10px;
    }

    .progress-icons {
        flex-direction: column;
        gap: 10px;
    }

    .create-activities_step1 .description input, 
    .create-activities_step1 .description textarea {
        border: 1px solid grey;
        padding: 20px 30px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 600;
    }

    .progress-icon::before {
        display: none;
    }

    .create-restaurant .create-activities_step1 .place-view {
        padding: 0px 10px;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width: 576px) {
    .create-restaurant {
        padding: 40px 10px 5px 10px;
    }

    .heading h3 {
        font-size: 20px;
    }

    .heading img {
        height: 60px;
        width: 60px;
    }

    .description-box,
    .add-location,
    .add-photos,
    .res-view,
    .res-time,
    .dining-style,
    .cuisines-style,
    .menu-photo,
    .res-offer,
    .create-activities_step1,
    .create-activities_step2 {
        padding: 5px;
    }

    .create-activities_step1 .description input, 
    .create-activities_step1 .description textarea {
        border: 1px solid grey;
        padding: 20px 30px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 600;
    }

    .progress-icons {
        flex-direction: column;
        gap: 5px;
    }

    .progress-icon::before {
        display: none;
    }

    .create-restaurant .create-activities_step1 .place-view {
        padding: 0px 5px;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width: 400px) {
    .create-restaurant {
        padding: 20px 5px 2px 5px;
    }
    
    .heading {
        display: flex;
        text-align: left;
        align-items: left;
        background-color: #eaf5fb;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 20px;
        margin-top: 100px;
        width: 355px;
    }

    .heading h3 {
        font-size: 18px;
    }

    .heading img {
        height: 50px;
        width: 50px;
    }

    .description-box,
    .add-location,
    .add-photos,
    .res-view,
    .res-time,
    .dining-style,
    .cuisines-style,
    .menu-photo,
    .res-offer,
    .create-activities_step1,
    .create-activities_step2 {
        padding: 2px;
    }

    .create-activities_step1 .description input, 
    .create-activities_step1 .description textarea {
        border: 1px solid grey;
        padding: 20px 30px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 600;
        width: 250px;
    }

    .progress-icons {
        flex-direction: column;
        gap: 2px;
    }

    .progress-icon::before {
        display: none;
    }

    .create-restaurant .create-activities_step1 .place-view {
        
        padding: 0px 2px;
        flex-direction: column;
        align-items: flex-start;
    }
}
