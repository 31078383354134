@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  width: 100%;
  padding:80px 200px;
}

.about-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-us {
  width: 100%;
  text-align: center;
}

.about-content {
  text-align: left;
  padding: 0;
}

.about-content h1 {
  font-size: 32px;
  color: #343a40;
}

.highlight-1 {
  color: #0C4670;
}

.about-content p {
  font-size: 18px;
  color: #6c757d;
  margin-bottom: 40px;
  line-height: 1.5;
  padding-left: 0px;
}

.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
}

.stat {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 150px;
}

.stat .icon {
  font-size: 40px;
  color: #0C4670;
  margin-bottom: 10px;
}

.number {
  font-size: 36px;
  color: #343a40;
  margin-bottom: 5px;
}

.label {
  font-size: 18px;
  color: #6c757d;
}

.explore-button {
  background-color: #0C4670;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.explore-button:hover {
  background-color: #093e5b;
}

.gallery {
display: flex;
  justify-content: center;
  padding-left: 40px;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
}

.gallery img {
  object-fit: cover;
  
}


.about-container .stat .icon {
  font-size: 40px;
  color: #0C4670; /* Dark blue color */
  margin-bottom: 10px;
}



.about1,
.about4{
  width: 250px;
  height: 250px;

}
.about2,
.about3{
  width: 180px;
  height: 180px;


}
.about2{
  margin-top: 73px;
}
.about3{
  margin-bottom: 73px;
}
.why-choose-us {
  width: 100vw;
  background-color: #0C4670;
  padding: 40px 200px;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
}
.why-choose-us-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  
}
.why-choose-us-text p{
  color: white;
  
  font-size: 18px;
  margin-top: 10px;
}

.why-choose-us h2 {
  font-size: 32px;
  color: white;
 text-align: center;
}

.why-choose-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-top: 20px;
}

.why-choose-item {
  position: relative;
  flex: 1;
  margin: 10px;
  overflow: hidden;
}

.why-choose-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: transform 0.3s ease;
}
.why-choose-item:hover img {
  transform: scale(1.1); /* Zoom out the image on hover */
}
.why-choose-item:hover .text-box {
  height: 50%; /* Increase the height to 50% on hover */
  transform: translateY(0); /* Move the text up on hover */
}
.text-box  {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25%; /* Cover half of the image */
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  transition: height 0.3s ease;

}

.text-box:hover {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%; /* Cover half of the image */
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  transform: translateY(100%); /* Initially move the text below the item */
  transition: transform 0.3s ease; 
}
.why-choose-item:hover .text-box {
  transform: translateY(0); /* Move the text up on hover */
}

.text-box h3 {
  margin: 0;
  font-size: 1.5em;
  display: block;
}

.text-box p {
  margin: 10px 0 0 0;
  font-size: 1em;
  color: black;
  display: none;
}
.why-choose-item:hover .text-box p {
  display: block;
}


.why-choose-item.small {
  height: 400px;
  
   /* Adjust this value as needed */
}

.why-choose-item.large {
  height: 400px; /* Adjust this value as needed */
}
@media (max-width: 768px) {

  .why-choose-us {
    width: 100vw;
    background-color: #0C4670;
    padding: 40px 20px; /* Adjusted for better responsiveness */
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .why-choose-us-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .why-choose-us-text p {
    color: white;
    font-size: 18px;
    margin-top: 10px;
  }
  
  .why-choose-us h2 {
    font-size: 32px;
    color: white;
    text-align: center;
  }
  
  .why-choose-container {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping */
    justify-content: space-between;
    gap: 20px;
    padding-top: 20px;
  }
  
  .why-choose-item {
    position: relative;
    flex: 1;
    margin: 10px;
    overflow: hidden;
  }
  
  .why-choose-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.3s ease;
  }
  
  .why-choose-item:hover img {
    transform: scale(1.1); /* Zoom out the image on hover */
  }
  
  .why-choose-item:hover .text-box {
    height: 50%; /* Increase the height to 50% on hover */
    transform: translateY(0); /* Move the text up on hover */
  }
  
  .text-box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25%; /* Cover half of the image */
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    transition: height 0.3s ease;
  }
  
  .text-box:hover {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%; /* Cover half of the image */
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    transform: translateY(100%); /* Initially move the text below the item */
    transition: transform 0.3s ease;
  }
  
  .why-choose-item:hover .text-box {
    transform: translateY(0); /* Move the text up on hover */
  }
  
  .text-box h3 {
    margin: 0;
    font-size: 1.5em;
    display: block;
  }
  
  .text-box p {
    margin: 10px 0 0 0;
    font-size: 1em;
    color: black;
    display: none;
  }
  
  .why-choose-item:hover .text-box p {
    display: block;
  }
  
  .why-choose-item.small {
    height: 400px; /* Adjust this value as needed */
  }
  
  .why-choose-item.large {
    height: 400px; /* Adjust this value as needed */
  }
  .why-choose-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .why-choose-item {
    margin: 10px 0; /* Adjust margin for smaller screens */
    flex-basis: 100%; /* Full width on small screens */
    height: auto; /* Adjust height for small screens */
  }

  .text-box {
    height: 30%; /* Adjust height for small screens */
  }

  .why-choose-item.small,
  .why-choose-item.large {
    height: 300px; /* Adjust height for small screens */
  }
}

 /* Additional styling for vlogs section */
.about .explore-vlogs {
  padding: 80px 200px;
  text-align: center;
}
.explore-vlogs h2 {
  padding-top: 40px;
  margin-bottom: 20px;
  font-size: 2em;
  color:#0C4670;
}

.vlogs-container{
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.vlogs-container-text{
  display: flex;
  align-items: center;
}
.vlogs-container-text p{
  color: #6c757d;
  font-size: 20px;
  padding: 20px 80px;
}

.vlogs-container-video {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.video-container-youtube, 
.video-container-insta,
.video-container-tiktok,
.video-container-facebook{
  position: relative;
  background-color: #fff;
  width: 250px;
  height: 300px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.video-overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  background: #0C4670;
  border-top-left-radius: 100%;
  transition: width 0.3s ease, height 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-overlay h2{
  color: #fff;
  font-size: 20px;
  
}
.video-overlay h2:hover{
  cursor: pointer; 
}

.video-container-youtube:hover .video-overlay,
.video-container-insta:hover .video-overlay,
.video-container-tiktok:hover .video-overlay,
.video-container-facebook:hover .video-overlay {
  width: 150px;
  height: 150px;
}
.videocontainer{
  padding: 20px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.videocontainer a{
  text-decoration: none;
}


.videocontainer .youtube{
  font-size: 90px;
  color: #ff0000;
}
.videocontainer .facebook{
  font-size: 90px;
  color: #4400ff;
}
.videocontainer .insta{
  font-size: 90px;
  color: #ff00c3;
}
.videocontainer .tik{
  font-size: 90px;
  color: #000000;
}




/* Media query for smaller screens */
@media only screen and (max-width: 768px) {
  /* Additional styling for vlogs section */
.about .explore-vlogs {
  padding: 40px 20px; /* Reduce padding for smaller screens */
  text-align: center;
}

.explore-vlogs h2 {
  padding-top: 20px; /* Reduce padding for smaller screens */
  margin-bottom: 10px; /* Reduce margin for smaller screens */
  font-size: 1.5em; /* Adjust font size for smaller screens */
  color: #0C4670;
}

.vlogs-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Reduce gap between elements for smaller screens */
}

.vlogs-container-text p {
  color: #6c757d;
  font-size: 16px; /* Adjust font size for smaller screens */
}

.vlogs-container-video {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.video-container-youtube,
.video-container-insta,
.video-container-tiktok,
.video-container-facebook {
  width: 100%; /* Make video containers full width on smaller screens */
  height: auto; /* Auto height for responsive scaling */
}

.videocontainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.videocontainer h1 {
  color: #fff;
}

.videocontainer .youtube {
  font-size: 60px; /* Decrease font size for smaller screens */
  color: #fff;
}

.overlay-icon {
  font-size: 80px; /* Decrease font size for smaller screens */
  color: red;
  display: none;
}

/* Show overlay icon on hover for smaller screens */
.video-container-youtube:hover .overlay-icon,
.video-container-insta:hover .overlay-icon,
.video-container-tiktok:hover .overlay-icon,
.video-container-facebook:hover .overlay-icon {
  display: block;
}

  .explore-vlogs h2 {
    font-size: 1.2em; /* Further reduce font size for smaller screens if needed */
  }

  .vlogs-container-text p {
    font-size: 14px; /* Further reduce font size for smaller screens if needed */
  }

  .videocontainer .youtube {
    font-size: 40px; /* Further decrease font size for smaller screens if needed */
  }

  .overlay-icon {
    font-size: 60px; /* Further decrease font size for smaller screens if needed */
  }
}


/* @keyframes slideAnimation {
  0%, 100% {
      z-index: -2;
     
  }
  50% {
      z-index: 1;
      
  }
} */

/* .vlogs-container-video {
    padding: 0 30px 30px 15px;
    position: relative;
} */

/* .vlogs-container-video .img-background-bg {
  animation: slideAnimation 12s infinite;
  animation-delay: 8s; 
    position: absolute;
    top: 70px;
    right: 0;
    left: 85px;
    bottom: 0;
    height: 480px;
    width: 350px;
    overflow: hidden;
    z-index: -2; 
} */

/* .vlogs-container-video .img-background {
  animation: slideAnimation 12s infinite;
  animation-delay: 6s; 
    position: absolute;
    top: 50px;
    right: 0;
    left: 65px;
    bottom: 0;
    height: 480px;
    width: 350px;
    overflow: hidden;
    z-index: -1; 
} */

/* .vlogs-container-video .img-before {
  animation: slideAnimation 12s infinite;
  animation-delay: 4s;
    position: absolute;
    top: 30px;
    right: 0;
    left: 45px;
    bottom: 0;
    height: 480px;
    width: 350px;
    overflow: hidden;
    z-index: 0;
} */

/* .background-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.vlogs-container-video img {
  animation: slideAnimation 12s infinite;
  overflow: hidden;
    position: relative;
    z-index: 1;
    transition: transform 0.7s ease;
} */

/* .img {
    width: 350px;
    height: 480px;
} */






/* .vlogs-container-video {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 40px;
  width: 100%;
  height: 500px;
}

.vlog-item {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
  transform: scale(0.8);
}

.vlog-item iframe {
  width: 100%;
  height: 100%;
}

.vlog-item.active {
  opacity: 1;
  transform: scale(1);
  z-index: 2;
}

.vlog-item.left,
.vlog-item.right {
  opacity: 0.5;
  z-index: 1;
}

.vlogs-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
} */


  
  

@media (min-width: 768px) {
  .about-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .about-us {
    width: 60%;
  }

  .stats {
    flex-direction: row;
    justify-content: center;
  }

  .gallery {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .about1,
  .about3 {
    width: calc(50% - 5px);
    /* Adjusting width for the first column */
  }

  .about2,
  .about4 {
    width: calc(50% - 5px); /* Adjusting width for the second column */
  }
  
}

@media (max-width: 767px) {
  .container {
    padding: 10px;
  }

  .about1,
  .about3,
  .about2,
  .about4 {
    width: 100%;
    height: auto; /* Make images take full width on small screens */
    margin: 10px; /* Adding margin to separate images in different rows */
  }


}
