.list {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-content: flex-start;
  align-items: center;
 
}

.title-list {
  margin: 40px 100px;
  color: blue;
}

.content {
  display: flex;
  justify-content: left;
}

.filter-box {
  width: 350px;
  padding: 20px;
  border-right: 1px solid #0c4670;
}

.filter-box h3 {
  margin-bottom: 20px;
  color: black;
}

.filter-item {
  margin-bottom: 40px;
  width: 300px;
}

.filter-item label {
  display: block;
  margin-bottom: 5px;
  color: black;
  padding: 10px;
}

.filter-box .filter-item input[type="text"]{
  width: 100%;
  padding: 10px 5px;
  box-sizing: border-box;
  border: 1px solid #0c4670;
  border-radius: 5px;
}

.filter-item input[type="range"] {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

.filter-item .price-value {
  margin-top: 10px;
}

.filter-item input[type="radio"] {
  margin-right: 10px;
}

.filter-item-type {
  display: flex;
  flex-direction: column;
}

.filter-item-type .type-option {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.star-rating-options label {
  color: #ffd700;
  font-size: 24px;
  cursor: pointer;
}

.star-rating-options input[type="checkbox"]:checked + label {
  color: #ffd700;
}

.type-option,
.facility-option,
.place-view-option,
.filter-availability-period {
  display: flex;
  gap: 10px;
}

.filter-box .head {
  color: #0c4670;
}

.price-range {
  display: flex;
  align-items: center;
}

.price-range input[type="range"] {
  margin: 0 10px;
}

.price-range span {
  width: 60px;
  text-align: center;
}

.checkbox-large {
  transform: scale(1.5);
  margin-right: 8px;
}

.filter-availability-period label {
  font-size: 16px;
}

.filter-item input[type="time"] {
  width: calc(100% - 20px);
  padding: 8px;
  margin: 5px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 14px;
}

.filter-item input[type="time"]:focus {
  border-color: #007bff;
  outline: none;
}

.filter-box .filter-item {
  margin-bottom: 15px;
}

.seat-count-filter,
.door-count-filter {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.seat-count-filter button,
.door-count-filter button {
  background-color: #0c4670;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 5px;
  border-radius: 3px;
}

.seat-count-filter button:hover,
.door-count-filter button:hover {
  background-color: #0056b3;
}

.seat-count-filter span,
.door-count-filter span {
  font-size: 16px;
  margin: 0 10px;
}

.mileage-range,
.rental-period {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mileage-range input[type="number"],
.rental-period input[type="number"] {
  width: 45%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.mileage-range input[type="number"]::placeholder,
.rental-period input[type="number"]::placeholder {
  color: #999;
}

/* Media Query for Small Devices */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
    align-items: center;
  }

  .title-list {
    margin: 40px 0;
  }

  .filter-box {
    width: 100%;
    max-width: none;
  }

  .filter-item {
    width: 100%;
  }
}
/* Default filter box style */
.filter-box {
  display: block;
}

/* Media Query for Small Devices */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .title-list {
    margin: 40px 0;
  }

  /* Hide the filter box by default on small devices */
  .filter-box {
    display: none;
    width: 100%;
    max-width: none;
  }

  /* Style for the filter button */
  .filter-button {
    display: block;
    position: absolute;
    bottom: 30px;
    right: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 20px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    z-index: 3;
  }

  /* Show the filter box when the show class is added */
  .filter-box.show {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    overflow-y: auto;
    z-index: 9998;
    padding: 20px;
  }
  .list{
    justify-content: center;
  }
}
@media (min-width: 769px) {
  .filter-button {
    display: none;
  }
}
/*seet alert*/
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border: none;
  border-radius: .25em;
  background: initial;
  background-color: #0c4670;
  color: #fff;
  font-size: 1em;
}