/* Footer.css */

.footer {
  background-color: #0a2c52;
  color: white;
  padding: 40px 100px;
  margin: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.contact-info {
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
  border: 1px solid #fff;
  padding: 20px;
}

.contact-item {
  display: flex;
  align-items: center;
  padding-right: 20px;
  flex-basis: 30%;
  border-right: 1px solid #fff;
}

.icon-container {
  background-color: #fff;
  border-radius: 50%;
  padding: 10px 12px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.icon-container .icontop {
  color: #0a2c52;
}

.contact-item h3 {
  margin: 0;
  font-size: 16px;
}

.contact-item p {
  margin: 0;
  font-size: 14px;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #fff;
}

.footer-section {
  flex: 1;
  margin: 20px;
}

.logo {
  margin-bottom: 20px;
  width: 100px;
  height: 120px;
}

.footer-section h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-section p {
  font-size: 16px;
  margin-bottom: 20px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin: 5px 0;
  display: flex;
}
.footer-section ul li a{
  text-decoration: none;
  color: inherit;
}

.social-icons .icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.subscribe {
  display: flex;
  align-items: center;
}

.subscribe input {
  padding: 10px;
  border: none;
  height: 40px;
  
  outline: none;
}

.subscribe button {
  padding: 10px;
  background-color: #00a6a6;
  color: white;
  border: none;
  height: 40px;
  
  cursor: pointer;
}

.subscribe button:hover {
  background-color: #008080;
}
.foot-last{
  margin-top: 10px;
  text-align: center;
}
.address-footer{
  border-right: none;
}


@media (max-width: 768px) {
  .footer{
    padding: 0px;
  }
  .contact-info {
    flex-direction: column;
    margin-bottom: 0px;
    border: none;
    
    padding: 20px;
    width: 350px;
    margin-right: 30px;
  }

  .contact-item {
    border-right: none;
   
    padding-right: 0;
    margin-bottom: 20px;
    flex-basis: 100%; /* Full width on small screens */
  }
  .contact-item:last-child {
    border-bottom: none;
  }

  .footer-content {
    flex-direction: column;
    padding-left: 10px;

  }

 

  .subscribe {
    flex-direction: column;
  }

  .subscribe input {
    width: 100%;
    margin-bottom: 10px;
  }

  .subscribe button {
    width: 100%;
  }
}
