/* Add to your existing CSS file or create a new one if you haven't already */
.navbar {
  top: 0;
  width: 100vw;
  padding: 0px 200px;
  justify-content: center;
  text-align: center;
  align-items: center;
  background: #0c4670;
  position: fixed;
  z-index: 1000;
  transition: background 0.3s ease;
}

.transparent {
  background: transparent;
  color: #273c75;
}

.navbaritems {
  width: 100%;
  display: flex;
  padding: 10px 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #fff;
}

.navbaritems img {
  width: 60px;
  height: 80px;
}

.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
}

.navitems {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navitems ul {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navitems ul li {
  margin: 0 20px;
}

.navitems ul li a {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.navitems ul li a:hover,
.navitems ul li .active {
  color: #008080;
  font-weight: 500;
}

.navbar_right_account {
  border-radius: 50%;
  border: none;
  height: 50px;
  width: 50px;
  position: relative;
  cursor: pointer;
}

.navbar_right_accountmenu {
  position: absolute;
  top: 100px;
  right: 60px;
  background: #fff;
  color: #0c4670;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  padding: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1001;
  min-width: 150px;
}

.navbar_right_accountmenu a {
  color: #0c4670;
  text-decoration: none;
  padding: 10px;
  text-align: left;
}

.navbar_right_accountmenu a:hover {
  background: #0c4670;
  color: #fff;
}

.service-dropdown {
  position: relative;
}

.service-button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.service-button:hover {
  color: #008080;
}

.service-dropdown .service-dropdown-content {
  position: absolute;
  top: 62px;
  padding: 10px;
  left: 0;
  background: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.navitems .service-dropdown .service-dropdown-content a {
  color: #0c4670;
  text-decoration: none;
  padding: 10px;
  text-align: left;
}

.navitems .service-dropdown .service-dropdown-content a:hover {
  background: #0c4670;
  color: #fff;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar {
    padding: 0 20px;
  }

  .navitems {
    position: fixed;
    top: 60px;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: #0c4670;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  .navitems.mobile-active {
    transform: translateX(0);
  }

  .navitems ul {
    flex-direction: column;
    width: 100%;
  }

  .navitems ul li {
    margin: 10px 0;
    
  }

  .navitems ul li a {
    font-size: 20px;
  }

  .service-dropdown .service-dropdown-content {
    position: static;
    box-shadow: none;
    padding: 0;
    text-align: center;
  }

  .service-dropdown .service-dropdown-content a {
    padding: 10px 20px;
  }

  .mobile-menu-button {
    display: block;
  }

  .navbar_right_accountmenu {
    top: 60px;
    right: 20px;
  }

  .close-menu-button {
    display: block;
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
    align-self: flex-end;
    margin-bottom: 20px;
  }
}
