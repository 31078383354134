/* Base styles for Categories component */

.categories {
  padding: 20px 80px; /* Adjusted padding for smaller devices */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #0C4670;
}

.categories h1 {
  color: #fff;
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 15px;
}

.categories p {
  max-width: 100%; /* Adjusted maximum width */
  font-size: 18px; /* Adjusted font size */
  color: #fff;
  padding: 20px 80px;
}

.categories_list {
  margin: 30px 0; /* Adjusted margin */
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center items horizontally */
}

.card {
  width: 300px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card-image {
  width: 100%;
  height: 400px; /* Adjusted height for smaller devices */
  transition: transform 0.3s ease;
}

.card-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(12, 70, 112, 0.1), rgba(12, 70, 112, 0.6));
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.card-content {
  width: 100%;
  color: #0C4670;
  background-color: #fff;
  padding: 10px;
  margin-top: -30px; /* Adjusted margin */
  z-index: 2;
}

.card-category {
  display: block;
  margin-bottom: 5px;
  font-size: 18px; /* Adjusted font size */
  font-weight: 700;
}

.card-content h2 {
  font-size: 14px; /* Adjusted font size */
  color: grey;
  text-align: left;
  padding-left: 10px;
}

.card:hover .card-content {
  margin-top: -130px; /* Adjusted margin */
}

.card:hover .card-overlay {
  display: flex;
}

/* Media Queries for Responsiveness */

@media screen and (max-width: 768px) {
  .categories h1 {
    font-size: 30px; /* Adjusted font size */
  }

  .categories p {
    font-size: 16px; /* Adjusted font size */
  }

  .card {
    width: 250px; /* Adjusted width for smaller devices */
  }

  .card-image {
    height: 250px; /* Adjusted height for smaller devices */
  }

  .card-content {
    margin-top: -20px; /* Adjusted margin */
  }

  .card-category {
    font-size: 16px; /* Adjusted font size */
  }

  .card-content h2 {
    font-size: 12px; /* Adjusted font size */
  }

  .card:hover .card-content {
    margin-top: -100px; /* Adjusted margin */
  }
}

@media screen and (max-width: 480px) {
  .categories h1 {
    font-size: 25px; /* Adjusted font size */
  }

  .categories p {
    font-size: 14px; /* Adjusted font size */
    padding:0px;
  }

  .card {
    width: 200px; /* Adjusted width for smaller devices */
  }

  .card-image {
    height: 200px; /* Adjusted height for smaller devices */
  }

  .card-content {
    margin-top: -10px; /* Adjusted margin */
  }

  .card-category {
    font-size: 14px; /* Adjusted font size */
  }

  .card-content h2 {
    font-size: 10px; /* Adjusted font size */
  }

  .card:hover .card-content {
    margin-top: -80px; /* Adjusted margin */
  }
}
