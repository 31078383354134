/* Desktop Styles */
.blogcontainer {
  width: 100vw;
  padding: 80px 200px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
}

.blogtitle {
  text-align: center;
  margin-bottom: 20px;
  width: 800px;
}

.blogtitle h1 {
  color: #0C4670;
  font-size: 40px;
  margin-bottom: 10px;
}

.blogtitle p {
  color: grey;
  font-size: 20px;
}

.blogview {
  position: relative;
  width: 100%;
  height: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.blogcard {
  position: absolute;
  width: 300px;
  padding: 0px 0px 10px 0px;
  opacity: 0;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  justify-content: center;
}

.blogcard.active {
  transform: translateX(0);
  opacity: 1;
  z-index: 2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.blogcard:not(.active) {
  opacity: 1;
}

.blogcard.left {
  transform: translateX(calc(-100% - 20px));
  opacity: 0.3;
}

.blogcard.right {
  transform: translateX(calc(100% + 20px));
  opacity: 0.3;
}

.blogcard img {
  width: 300px;
  height: 300px;
  margin-bottom: 20px;
  object-fit: cover;
}

.blogcard h2 {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #0C4670;
  text-align: left;
}

.blogcard p {
  font-size: 16px;
  padding: 0px 5px 0px 10px;
  color: rgb(170, 170, 170);
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blogcard button {
  background-color: #0C4670;
  color: #fff;
  border: none;
  font-size: 15px;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.blogcontainer .blogview .arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #0C4670;
  color: white;
  border-radius: 50%;
  border: none;
  font-size: 30px;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 3;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .blogcontainer {
    padding: 40px 20px;
  }

  .blogtitle {
    width: 100%;
  }

  .blogtitle h1 {
    font-size: 28px;
  }

  .blogtitle p {
    font-size: 16px;
  }

  .blogview {
    height: 450px;
  }

  .blogcard {
    width: 90%;
    max-width: 300px;
  }

  .blogcard img {
    width: 100%;
    height: 250px;
  }

  .blogcard h2 {
    font-size: 20px;
  }

  .blogcard p {
    font-size: 14px;
  }

  .blogcard button {
    font-size: 14px;
    padding: 10px 15px;
  }

  .blogcontainer .blogview .arrow {
    font-size: 24px;
    padding: 8px 15px;
  }
}

/* Mobile Specific Changes for Single Blog Display */
@media screen and (max-width: 480px) {
  .blogview {
    height: 400px;
  }

  .blogcard {
    width: 95%;
  }

  .blogcard img {
    height: 200px;
  }

  .blogtitle h1 {
    font-size: 24px;
  }

  .blogtitle p {
    font-size: 14px;
  }
}
