.create-hotel{
    width: 100vw;
    padding: 120px 90px 40px 90px;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: #f6f4f4;
}

.create-hotel .crmhotel ,.create-hotel .crroom{
    
    padding: 0px 50px 30px 50px;
    margin: 30px 0px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
}
.create-hotel .crmhotel h2{
    padding: 20px 0px;
    color: #0C4670;
}
.create-hotel .crmhotel .crhotel ,.create-hotel .crroom .crroomc{
    display: flex;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    background-color:  #eaf5fb;

}
.create-hotel .crmhotel .crhotel .crcontent{
    display: flex;
    flex-direction: column;
   align-items: flex-start;
}
.create-hotel .crmhotel .crhotel .crphoto{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.create-hotel .crmhotel .crhotel .crphoto .photos{
    display: flex;
    gap: 10px;
}

.create-hotel .crhotel .crphoto .photos img{
    width: 300px;
    margin: 30px 0px 30px 30px;

    height: 300px;
    border-radius: 10px;
   
}


.create-hotel .crroom h2{
    padding: 20px 0px;
    color: #0C4670;
}



.create-hotel .crroom .crroomc .crroomsd img{
    height: 300px;
    width: 300px;
    border-radius: 10px;
}
.create-hotel .rooms-details img {
    height: 100px;
    width: 150px;
}

.create-hotel h1{
    color: #0C4670;
    font-size: 40px;
    margin-bottom: 30px;
}

.create-hotel form{
    
}
.create-listing_step1{
    background-color: #fff;
    padding: 20px;
    border-radius: 13px;
    margin-bottom: 30px;
}
.create-listing_step1 .hoteltitle{
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: left;
    padding: 0px 40px;

}
.create-listing_step1 .hoteltitle h2{
    color: #0C4670;
    text-align: left;
    padding: 20px 0px;
}
.create-listing_step1 .hoteltitle input{
    border: 1px solid grey;
    padding: 10px 30px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    width: 600px;
}

.create-listing_step1 h2{
    color: #0C4670;
    text-align: left;
    padding: 20px 0px;
}

.create-listing_step1 h3{
    color: #0C4670;
    font-size: 20px;
    margin: 40px 0px;
    text-align: left;
}

.create-listing_step2 .type-list{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 40px;
}

.create-listing_step2 .type-list .type{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 600px;
    padding: 15px 30px;
    border: 1px solid grey;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease;
}

.create-listing_step2 .type-list .selected{
    border: 2px solid #0C4670;
    background-color: #0C4670;
    color: #fff;
}

.create-listing_step2 .type-list .type_text{
    max-width: 400px;
}
.create-listing_step2 .type-list .type_text h4{
    margin-bottom: 5px;
}

.create-listing_step2 .type-list .type_icon{
    font-size: 30px;
    color: #0C4670;
}




.create-listing_step1 .half .location{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 10px;
    padding: 0px 40px;
}

.create-listing_step1 .half .location input{
    border: 1px solid #000;
    border-radius: 5px;
    overflow: hidden;
    padding: 10px 20px;
}


.create-listing_step1 .half{
    max-width: 800px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.create-listing_step1 .star-rating {
    display: flex;
    flex-direction: column;  
    gap: 5px;
    padding: 0px 40px;
  }
  
  .star-rating label {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .star-rating input[type="radio"] {
    cursor: pointer;
    color: #0C4670;
  }
  
  .star-rating .star {
    font-size: 1.5rem;
    color: gold;
  }

  .create-listing_step1 .place-view {
    padding: 0px 40px;
    justify-content: left;
    display: flex;
  }
  
  .place-view select {
    width: 60%;
    padding: 10px;
    font-size: 16px;
  }
  
  
  
  

  .create-listing_step1 .basics{
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding: 0px 40px;
}

.basic{
    display: flex;
     align-items: center;
      gap: 30px;
     padding: 15px;
        border: 1px solid grey;
    border-radius: 10px;
}

.basic p{
    font-weight: 600;
}

.basic .basic_count{
    display: flex;
    align-items: center;
    gap: 9px;
    font-size: 20px;
}


.create-listing_step2{
    background-color: #fff;
    padding: 20px 20px 50px 20px;
    border-radius: 13px;
    margin-bottom: 30px;
}

.create-listing_step2 h2{
    color: #0C4670;
    text-align: left;
    padding: 20px 0px;
}

.create-listing_step2 h3{
    color: #0C4670;
    font-size: 20px;
    margin: 40px 0px;
    text-align: left;
}

.amenities{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0px 40px;
}
.amenities .facility{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 90px;
    border: 1px solid grey;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s ease;
}

.amenities .facility .facility_icon{
    font-size: 30px;
    color: #0C4670;
}
.create-listing_step2 .amenities .facility p{
    font-weight: 600;
}

.create-listing_step2 .amenities .facility:hover{
    
    background-color: #0C4670;
    color: #fff;
}
.create-listing_step2 .amenities .facility:hover .facility_icon{
    color: #fff;
}

.create-listing_step2 .amenities .selected{
    background-color: #0C4670;
    color: #fff;
}

.create-listing_step2 .amenities .selected .facility_icon{
    color: #fff;
}

.amenities .service{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 90px;
    border: 1px solid grey;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s ease;
}

.amenities .service .facility_icon{
    font-size: 30px;
    color: #0C4670;
}
.create-activities_step1 .amenities .service p{
    font-weight: 600;
}

.create-activities_step1 .amenities .service:hover{
    
    background-color: #0C4670;
    color: #fff;
}
.create-activities_step1 .amenities .service:hover .facility_icon{
    color: #fff;
}

.create-activities_step1 .amenities .selected{
    background-color: #0C4670;
    color: #fff;
}

.create-activities_step1 .amenities .selected .facility_icon{
    color: #fff;
}



/* .create-listing_step2 input{
    border: 1px solid red;
    padding: 15px 30px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
} */
.create-listing_step2 .description{
    text-align: left;
    padding: 0px 40px;
}
.create-listing_step2 .description p{
    font-weight: 700;
    margin: 20px 0 10px;

}
.create-listing_step2 .description input,textarea{
    border: 1px solid grey;
    padding: 10px 30px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    width: 600px;
}
.create-listing_step2 .description span{
    font-size: 25px;
   font-weight: 700;
    margin-right: 20px;
}
.create-listing_step2 .description .price{
    width: 200px;
}

.submit_btn{
    margin:40px 0px 50px 0px;
    padding:  20px;
    background-color: #0C4670;
    color: #fff;
    border-radius: 10px;
    border: none;
    font-size: 20px;
    font-weight: 700;
}
.submit_btn:hover{
    cursor: pointer;
    background-color: #fff;
    color: #0C4670;
    border: 1px solid #0C4670;
}

.create-room-btn{
    margin-right: 10px;
    padding:  20px;
    background-color: #0C4670;
    color: #fff;
    border-radius: 10px;
    border: none;
    font-size: 20px;
    font-weight: 700;
}
table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px; 
  }
  
.th-hotel ,.td-hotel {
    border: 1px solid #ddd;
   
    padding: 20px 20px;
    
  }
  
  .th-hotel {
    background-color: #f2f2f2;
    text-align: left;
  }
  
 
  
 

.crroomst{
    margin: 30px 0px 30px 30px;
}

.create-listing_step2 .description-box .uploaded-image-container{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
}

.create-listing_step2 .description-box .uploaded-image-container .uploaded-image{
    display: flex;
    flex-direction: column;
}

.create-listing_step2 .description-box .uploaded-image-container img{
    width: 250px;
    height: 200px;
}

.create-listing_step2 .description-box input{
    border: 1px solid grey;
    width: 300px;
    padding: 10px 20px;
    margin-bottom: 10px;
}
 
  /* Responsive styles */
@media (max-width: 1024px) {
    .create-hotel, .create-listing_step1, .create-listing_step2 {
        padding: 60px 5vw 20px;
    }
    
    .create-hotel .crmhotel, .create-hotel .crroom {
        padding: 15px;
    }
    
    .create-hotel .crmhotel .crhotel, .create-hotel .crroom .crroomc {
        padding: 15px;
    }
}

@media (max-width: 768px) {
    .create-hotel, .create-listing_step1, .create-listing_step2 {
        padding: 40px 3vw 20px;
    }

    .create-hotel h1 {
        font-size: 32px;
    }

    .create-listing_step1 .hoteltitle, .create-listing_step1 .half, .create-listing_step1 .star-rating,
    .create-listing_step1 .place-view, .create-listing_step1 .basics, .create-listing_step1 .photos,
    .create-listing_step2 .type-list, .create-listing_step2 .amenities, .create-listing_step2 .roomPhotos,
    .create-listing_step2 .description {
        padding: 0 20px;
    }

    .create-listing_step1 .hoteltitle h2, .create-listing_step1 h2, .create-listing_step1 h3,
    .create-listing_step2 h2, .create-listing_step2 h3 {
        padding: 15px 0;
    }

    .create-listing_step1 .hoteltitle input,
    .create-listing_step1 .half .location input,
    .create-listing_step1 .description input,
    .create-listing_step1 .description textarea,
    .create-listing_step1 .place-view select,
    .create-listing_step2 .description input,
    .create-listing_step2 .description textarea,
    .create-listing_step2 .description .price {
        padding: 8px;
    }
}

@media (max-width: 480px) {
    .create-hotel, .create-listing_step1, .create-listing_step2 {
        padding: 30px 2vw 10px;
    }

    .create-hotel h1 {
        font-size: 28px;
    }

    .create-listing_step1 .hoteltitle h2, .create-listing_step1 h2, .create-listing_step1 h3,
    .create-listing_step2 h2, .create-listing_step2 h3 {
        font-size: 18px;
        padding: 10px 0;
    }

    .create-listing_step1 .hoteltitle input,
    .create-listing_step1 .half .location input,
    .create-listing_step1 .description input,
    .create-listing_step1 .description textarea,
    .create-listing_step1 .place-view select,
    .create-listing_step2 .description input,
    .create-listing_step2 .description textarea,
    .create-listing_step2 .description .price {
        padding: 5px;
        width: 300px;
    }

    .submit_btn, .create-room-btn {
        font-size: 16px;
        padding: 10px;
    }

    .create-listing_step1 .photos .photo, .create-listing_step2 .roomPhotos .photo {
        height: 120px;
    }
}
/* Media Queries for Responsive Design */

@media (max-width: 768px) {
    .crcontent table {
      width: 100%;
      border-collapse: collapse; /* Ensure table borders collapse on small screens */
    }
    .crcontent table th,
    .crcontent table td {
      display: block;
      text-align: left;
      padding: 8px; /* Add padding for better spacing */
    }
    .crcontent table th {
      width: 100%; /* Make table headers span the full width */
    }
    .crcontent table td {
      width: 100%; /* Make table data cells span the full width */
      border-bottom: 1px solid #ddd; /* Add bottom border for better separation */
    }
    .create-hotel .crmhotel .crhotel, .create-hotel .crroom .crroomc {
        display: flex;
        justify-content: space-between;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 20px;
        background-color: #eaf5fb;
        flex-direction: column;
    }
    .create-hotel .crhotel .crphoto .photos img {
        width: 250px;
        margin: 30px 0px 30px 0px;
       
        height: 300px;
        border-radius: 10px;
    }
    .create-hotel .crmhotel h2{
        margin-top:20px;
    }
    .create-hotel .crroom .crroomc .crroomsd img {
        height: 300px;
        width: 250px;
        border-radius: 10px;
    }
  }
  