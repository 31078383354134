.property-list{
    width: 100vw;
    padding: 120px 100px 40px 100px;
    display: flex;
    flex-direction: column;
    align-items: first baseline; 
}

.property-list h1{
    color: #0C4670;
}

.property-list .list-pro{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px 110px;
    

}



